import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService, PROPERTY_ID } from 'src/app/api.service';
import { Property } from 'src/app/model/property';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-home-about',
  templateUrl: './home-about.component.html',
  styleUrls: ['./home-about.component.css']
})
export class HomeAboutComponent implements OnInit {
  property?: Property; // Mark property as optional
  currentIndex: number = 0; // To track the current slide index for the slider
  slideItems: { src: string; alt: string }[] = []; // Define the type for `slideItems`

  constructor(
    public token: TokenStorage,
    public apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getProperty();
  }

  getProperty() {
    this.apiService.getPropertyDetailsByPropertyId(PROPERTY_ID).subscribe(
      (response) => {
        this.property = response.body; // Assign response body to `property`
        this.token.saveProperty(this.property); // Save the property data
        this.initializeSlideItems(); // Initialize slide items after fetching property details
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
          console.error('Error fetching property details:', error.message);
        }
      }
    );
  }

  initializeSlideItems() {
    // Check if imageList is defined and has a length
    const defaultImages = [
      'assets/images/1.jpeg',
      'assets/images/2.jpeg',
      'assets/images/3.jpeg',
      'assets/images/4.jpeg',
      'assets/images/5.jpeg'
    ];
  
    this.slideItems = defaultImages.map((defaultImage, index) => ({
      src: this.property?.imageList?.[index]?.url || defaultImage,
      alt: 'overview'
    }));
  }
  

  bookYourStay() {
    const property = this.token.getProperty(); // Store in a local constant to avoid multiple undefined checks
    if (property) {
      window.open(
        `https://bookone.io/${property.seoFriendlyName}?BookingEngine=true`,
        '_blank'
      );
    }
  }

  // Slider functionality
  nextSlide() {
    if (this.currentIndex < this.slideItems.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0; // Loop back to the first slide
    }
  }

  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.slideItems.length - 1; // Loop back to the last slide
    }
  }

  // This method will allow the component to listen to keyboard events for navigation
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.prevSlide();
    } else if (event.key === 'ArrowRight') {
      this.nextSlide();
    }
  }
}
